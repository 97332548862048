import React from 'react';
import './NotFoundPage.css';

function ErrorPage({ message = 'Product not found!', onRetry }) {
    return (
        <div className="error-page">
            <div className="error-content">
                <img
                    src="/path/to/error-illustration.png" // Replace with your illustration path
                    alt="Error Illustration"
                    className="error-illustration"
                />
                <h1 className="error-title">Oops!</h1>
                <p className="error-message">{message}</p>
                <div className="error-actions">
                    <button className="error-button" onClick={onRetry}>
                        Retry
                    </button>
                    <a href="/" className="error-link">
                        Go to Homepage
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
