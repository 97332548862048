import React from 'react';
import './RelatedProduct.css';

function RelatedProduct({ productName, imageUrl, manufacturerName, manufacturerLink }) {
    return (
        <div className="related-product">
            <div className="product-image-wrapper">
                <img src={imageUrl} alt={productName} className="product-image" />
            </div>
            <p className="product-name">{productName}</p>
            <a
                href={manufacturerLink}
                target="_blank"
                rel="noopener noreferrer"
                className="manufacturer-name"
            >
                {manufacturerName}
            </a>
        </div>
    );
}

export default RelatedProduct;
