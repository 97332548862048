import React from 'react';
import './ProductSpecifications.css';

function ProductSpecifications({ specifications }) {
    return (
        <div className="product-specifications">
            {Object.entries(specifications).map(([key, value]) => (
                <div className="spec-row" key={key}>
                    <span className="spec-key">{key}</span>
                    <span className="spec-value">{value}</span>
                </div>
            ))}
        </div>
    );
}

export default ProductSpecifications;
