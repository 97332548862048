import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage.js';
import HomePage from './pages/HomePage/HomePage.js';
import ProductPage from './pages/ProductPage/ProductPage.js';

function App() {
    return (
        <Router>
            <Routes>
                {/* Home page route */}
                <Route path="/" element={<HomePage />} />

                {/* Dynamic product page route */}
                <Route path="/:productId" element={<ProductPage />} />

                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
}

export default App;
