import React from 'react';
import './ReviewList.css';
import ProductReview from '../ProductReview/ProductReview.js';

function ReviewList({ reviews, onUpvote }) {
    return (
        <div className="review-list">
            {reviews.map((review, index) => (
                <React.Fragment key={review.id}>
                <ProductReview
                    id={review.id}
                    title={review.title}
                    body={review.body}
                    link={review.link}
                    rating={review.rating}
                    username={review.username}
                    upvotes={review.upvotes}
                    timestamp={review.timestamp}
                    onUpvote={() => onUpvote(review.id)}
                />
                {index < reviews.length - 1 && <hr className="review-divider" />}
                </React.Fragment>
            ))}
        </div>
    );
}

export default ReviewList;
