import React from 'react';
import './ProductDescription.css';

function ProductDescription({ description, aiGenerated }) {
    return (
        <div className="product-description">
            <p className="description-text">{description}</p>
            {aiGenerated && (
                <div className="ai-footnote">
                    <i className="bi bi-stars ai-icon" />
                    <span className="ai-text">AI Generated</span>
                </div>
            )}
        </div>
    );
}

export default ProductDescription;
