import React from 'react';
import './RatingStars.css';

function RatingStars({ rating, numReviews }) {
    // Calculate full, half, and empty stars
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
        <div className="rating-stars">
            <span className="rating-value">{rating.toFixed(1)}</span>
            <div className="stars">
                {/* Full stars */}
                {[...Array(fullStars)].map((_, index) => (
                    <i key={`full-${index}`} className="bi bi-star-fill star" />
                ))}
                {/* Half star */}
                {hasHalfStar && <i className="bi bi-star-half star" />}
                {/* Empty stars */}
                {[...Array(emptyStars)].map((_, index) => (
                    <i key={`empty-${index}`} className="bi bi-star star empty" />
                ))}
            </div>
            <span className="num-reviews">({numReviews.toLocaleString()})</span>
        </div>
    );
}

export default RatingStars;
