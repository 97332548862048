import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './HomePage.css';

function App() {
    return (
        <div style={{ fontFamily: 'SF Pro', fontSize: '20px' }}>
            UPCDB.
        </div>
    );
}

export default App;
