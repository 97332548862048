import React, { useState, useEffect } from 'react';
import './HeroCarousel.css';

function HeroCarousel({ images, transitionSpeed = 3000 }) {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, transitionSpeed);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [images.length, transitionSpeed]);

    return (
        <div className="carousel-container">
            <div className="carousel-image-wrapper">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Slide ${index + 1}`}
                        className={`carousel-image ${currentIndex === index ? 'active' : ''}`}
                    />
                ))}
                <div className="gradient-overlay"></div>
            </div>
            <div className="carousel-indicators">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`indicator ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default HeroCarousel;
