import React from 'react';
import './LoadingScreen.css';

function LoadingScreen({ message = 'Loading...' }) {
    return (
        <div className="loading-screen">
            <div className="spinner"></div>
            <p className="loading-message">{message}</p>
        </div>
    );
}

export default LoadingScreen;
