import React, { useState } from 'react';
import './FAQ.css';

function FAQ({ questions }) {
    const [openIndex, setOpenIndex] = useState(null); // Track which question is open

    const toggleQuestion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Close if already open
    };

    return (
        <div className="faq-container">
            {questions.map((item, index) => (
                <div key={index} className="faq-item">
                    {/* Question Row */}
                    <div
                        className="faq-question"
                        onClick={() => toggleQuestion(index)}
                    >
                        <span className="faq-text">{item.question}</span>
                        <i
                            className={`faq-indicator bi ${
                                openIndex === index
                                    ? 'bi-chevron-down open'
                                    : 'bi-chevron-down'
                            }`}
                        />
                    </div>
                    {/* Answer Section */}
                    {openIndex === index && (
                        <div className="faq-answer">{item.answer}</div>
                    )}
                    {/* Divider */}
                    {index < questions.length - 1 && (
                        <div className="faq-divider"></div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default FAQ;
