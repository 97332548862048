import React from 'react';
import './VideoList.css';
import ProductVideo from '../ProductVideo/ProductVideo.js';

function VideoList({ videos }) {
    return (
        <div className="video-list">
            {videos.map((video, index) => (
                <ProductVideo
                    key={index}
                    videoLink={video.videoLink}
                    thumbnail={video.thumbnail}
                    authorName={video.authorName}
                    authorLink={video.authorLink}
                />
            ))}
        </div>
    );
}

export default VideoList;
