import React from 'react';
import './RelatedProductList.css';
import RelatedProduct from '../RelatedProduct/RelatedProduct.js';

function RelatedProductList({ products }) {
    // Determine layout: first row gets the greater amount if odd number of products
    const firstRowCount = Math.ceil(products.length / 2);
    const secondRowCount = products.length - firstRowCount;

    const firstRowProducts = products.slice(0, firstRowCount);
    const secondRowProducts = products.slice(firstRowCount);

    return (
        <div className="related-products-container">
            <div className="product-row">
                {firstRowProducts.map((product, index) => (
                    <RelatedProduct
                        key={index}
                        productName={product.productName}
                        imageUrl={product.imageUrl}
                        manufacturerName={product.manufacturerName}
                        manufacturerLink={product.manufacturerLink}
                    />
                ))}
            </div>
            {secondRowCount > 0 && (
                <div className="product-row">
                    {secondRowProducts.map((product, index) => (
                        <RelatedProduct
                            key={index}
                            productName={product.productName}
                            imageUrl={product.imageUrl}
                            manufacturerName={product.manufacturerName}
                            manufacturerLink={product.manufacturerLink}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default RelatedProductList;
