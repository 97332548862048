import React, { useState } from 'react';
import './ProductReview.css';
import { formatDistanceToNow } from 'date-fns'; // To format the timestamp

function ProductReview({
    id,
    title,
    body,
    link,
    rating,
    timestamp,
    username,
    upvotes,
    onUpvote,
}) {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    // Limit for the portion of the review to show
    const previewLimit = 200; // Adjust the limit as needed

    return (
        <div className="product-review">
            {/* Avatar and Name */}
            <div className="review-header">
                <img
                    src={`https://ui-avatars.com/api/?name=${username}&background=random`}
                    alt={username}
                    className="review-avatar"
                />
                <div>
                    <a href={link} target="_blank" rel="noopener noreferrer" className="review-name">
                        {username}
                    </a>
                    <div className="review-rating">
                        <span className="filled-stars">{'★'.repeat(rating)}</span>
                        <span className="unfilled-stars">{'☆'.repeat(5 - rating)}</span>
                        <span className="review-date">{formatDistanceToNow(new Date(timestamp), { addSuffix: true })}</span>
                    </div>
                </div>
            </div>

            {/* Rating and Title */}
            <div className="review-body">
                <h3 className="review-title">{title}</h3>
                <p className="review-text">
                    {isExpanded || body.length <= previewLimit ? (
                        body
                    ) : (
                        <>
                            {body.slice(0, previewLimit)}...
                            <button className="read-more-button" onClick={toggleReadMore}>
                                {isExpanded ? 'Collapse' : 'Read more'}
                            </button>
                        </>
                    )}
                </p>
            </div>

            {/* Footer with Upvote Button */}
            <div className="review-footer">
                {upvotes > 0 && (
                    <p className="upvote-button" onClick={onUpvote}>
                        👍 {upvotes} people upvoted this
                    </p>
                )}
            </div>
        </div>
    );
}

export default ProductReview;
