import React from 'react';
import './RatingSummary.css';

function RatingSummary({ rating, rating_breakdown, num_ratings }) {
    return (
        <div className="rating-summary">
            <div className="rating-overview">
                <h1 className="rating-score">{rating.toFixed(1)}</h1>
                <p className="rating-total">Based on {num_ratings} ratings</p>
            </div>

            <div className="rating-breakdown">
                {['five_star', 'four_star', 'three_star', 'two_star', 'one_star'].map((key, index) => (
                <div className="rating-row" key={index}>
                    <span className="rating-label">
                        {5 - index} <i className="bi bi-star-fill star" />
                    </span>
                    <div className="rating-bar">
                        <div
                            className="rating-bar-fill"
                            style={{ width: `${rating_breakdown[key].percentage}%` }}
                        ></div>
                    </div>
                    <span className="rating-count">{rating_breakdown[key].count}</span>
                </div>
                ))}
            </div>
        </div>
    );
}

export default RatingSummary;
