import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProductPage.css';
import Logo from '../../assets/images/UPCDB.svg';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen.js';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage.js';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel.js';
import RatingStars from '../../components/RatingStars/RatingStars.js';
import ProductDescription from '../../components/ProductDescription/ProductDescription.js';
import Tabs from '../../components/Tabs/Tabs.js';
import ProductSpecifications from '../../components/ProductSpecifications/ProductSpecifications.js';
import VariantGrid from '../../components/VariantGrid/VariantGrid.js';
import FAQ from '../../components/FAQ/FAQ.js';
import VideoList from '../../components/VideoList/VideoList.js';
import RelatedProductList from '../../components/RelatedProductList/RelatedProductList.js';
import RatingSummary from '../../components/RatingSummary/RatingSummary.js';
import ReviewList from '../../components/ReviewList/ReviewList.js';

function ProductPage() {
    const { productId } = useParams(); // Extract the dynamic product ID from the URL
    const [product, setProduct] = useState(null); // State to store product data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`https://upcdb.up.railway.app/products/${productId}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch product: ${response.statusText}`);
                }
                const data = await response.json();
                console.log(data);
                setProduct(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchProduct();
    }, [productId]);

    // Handle loading and error states
    if (loading) {
        return <LoadingScreen message="Loading product data" />;
    }

    if (error) {
        <NotFoundPage
            message={`Error: ${error}`}
        />
    }

    // If product is null or undefined (e.g., no match found), show a fallback message
    if (!product) {
        return <NotFoundPage
            message={`No product found with UPC: ${productId}`}
        />
    }

    // Destructure the product data for easier rendering
    const {
        upc,
        title,
        description,
        description_is_ai,
        brand,
        brand_link,
        rating,
        num_ratings,
        num_reviews,
        images,
        categories,
        keywords,
        bestsellers_rank,
        value_breakdowns,
        reviews,
        features,
        ingredients,
        directions,
        specifications,
        recent_sales,
        variants,
        rating_breakdown,
        faq,
        videos,
        relatedProducts
    } = product;
    
    // const questions = [
    //     {
    //         question: "What is your return policy?",
    //         answer: "You can return any item within 30 days for a full refund.",
    //     },
    //     {
    //         question: "Do you ship internationally?",
    //         answer: "Yes, we ship to most countries worldwide. Shipping costs apply.",
    //     }
    // ];

    // const videos = [
    //     {
    //         videoLink: 'https://www.tiktok.com/@hannah.star/video/1234567890',
    //         thumbnail: 'https://p16-sign.tiktokcdn-us.com/tos-useast5-p-0068-tx/owOgKcmaQaWwCRxIQF2BNHAYBjLeZkefSAjGUI~tplv-tiktokx-360p.webp?dr=1363&nonce=57387&refresh_token=22758ceadb2fe242adb00e6f0fb27396&x-expires=1732676400&x-signature=wJU%2FrPn6Ah0aT5WTut8WyAZFkZI%3D&biz_tag=tt_video&idc=my&ps=d97f9a4f&s=AWEME_DETAIL&sc=cover&shcp=1d1a97fc&shp=d05b14bd&t=bacd0480',
    //         authorName: '', // This will be deduced automatically
    //         authorLink: '', // This will be deduced automatically
    //     },
    //     {
    //         videoLink: 'https://example.com/video1',
    //         thumbnail: 'https://p16-pu-sign-useast8.tiktokcdn-us.com/tos-useast8-p-0068-tx2/39439cc613f446bba04ceab285f6a8a9_1720802172~tplv-tiktokx-360p.webp?dr=1363&nonce=85797&refresh_token=4c99130b22353ca700184ff22ab11da1&x-expires=1732676400&x-signature=HL8U0Ps%2FRgJnlRTsg7tqqY5W%2BkI%3D&biz_tag=tt_video&idc=my&ps=d97f9a4f&s=AWEME_DETAIL&sc=cover&shcp=1d1a97fc&shp=d05b14bd&t=bacd0480',
    //         authorName: '@jessicareviews',
    //         authorLink: 'https://example.com/jessicareviews',
    //     }
    // ];

    // const relatedProducts = [
    //     {
    //         productName: 'Azeliac Acid Suspension 10%',
    //         imageUrl: 'https://www.spacenk.com/on/demandware.static/-/Sites-spacenkmastercatalog/default/dw837e86a4/products/ORDINARY/UK200029798_ORDINARY_4.jpg',
    //         manufacturerName: 'The Ordinary',
    //         manufacturerLink: 'https://www.deciem.com/',
    //     },
    //     {
    //         productName: 'Soothing & Barrier Support Serum',
    //         imageUrl: 'https://m.media-amazon.com/images/I/51Z6JMOK2iL.jpg',
    //         manufacturerName: 'The Ordinary',
    //         manufacturerLink: 'https://www.deciem.com/',
    //     },
    //     {
    //         productName: 'AHA 30% + 2% Peeling Solution',
    //         imageUrl: 'https://dazzlinworldbeauty.com/cdn/shop/products/image_cabea08c-840d-436f-8468-dba03ff7f169.jpg?v=1655902882',
    //         manufacturerName: 'The Ordinary',
    //         manufacturerLink: 'https://www.deciem.com/',
    //     },
    //     {
    //         productName: 'Hyaluronic Acid 2% + B5',
    //         imageUrl: 'https://m.media-amazon.com/images/I/61svd3TIGIL._AC_UF350,350_QL80_.jpg',
    //         manufacturerName: 'The Ordinary',
    //         manufacturerLink: 'https://www.deciem.com/',
    //     },
    // ];

    const tabs = [
        { 
            label: 'Overview', 
            content: 
                <div>
                    {specifications && (
                    <>
                        <h3>Specifications</h3>
                        <ProductSpecifications specifications={specifications} />
                        <br />
                    </>
                    )}
                    {variants && (
                    <>
                        <h3>Available as</h3>
                        {/* <VariantGrid variants={variants} /> */}
                        <br />
                    </>
                    )}
                    {faq && (
                    <>
                        <h3>FAQ</h3>
                        <FAQ questions={faq} />
                        <br />
                    </>
                    )}
                    {videos && (
                    <>
                        <h3>User-Generated Content</h3>
                        <VideoList videos={videos} />
                        <br />
                    </>
                    )}
                    {relatedProducts && (
                    <>
                        <h3>Related Products</h3>
                        <RelatedProductList products={relatedProducts} />
                        <br />
                    </>
                    )}
                </div> 
        },
        { 
            label: 'Reviews', 
            content: 
                <div>
                    <RatingSummary
                        rating={rating}
                        rating_breakdown={rating_breakdown}
                        num_ratings={num_ratings}
                    />
                    <ReviewList reviews={reviews} />
                </div> 
        },
        { label: 'Where to Buy', content: <p>This is the Where to Buy section.</p> },
    ];
        
    return (
        <div>
            <img src={Logo} alt="Logo" className="floating-logo" />
            <HeroCarousel images={images} transitionSpeed={5000} />
            <div id="primary-container">
                <h1>{title}</h1>
                <a href={brand_link}>{brand}</a>
                <RatingStars rating={rating} numReviews={num_ratings} /> 
                <ProductDescription
                    description={description}
                    aiGenerated={description_is_ai}
                />
                <Tabs tabs={tabs} />
            </div>
        </div>
    );
}

export default ProductPage;
