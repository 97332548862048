import React from 'react';
import './ProductVideo.css';

function ProductVideo({ videoLink, thumbnail, authorName, authorLink }) {
    // Deduce author name and link for TikTok videos
    if (videoLink.includes('tiktok.com')) {
        const matchedAuthor = videoLink.match(/tiktok\.com\/@(.*?)\/video/);
        if (matchedAuthor) {
            authorName = `@${matchedAuthor[1]}`;
            authorLink = `https://www.tiktok.com/@${matchedAuthor[1]}`;
        }
    }

    return (
        <div className="product-video">
            <a href={videoLink} target="_blank" rel="noopener noreferrer" className="video-thumbnail-wrapper">
                <img src={thumbnail} alt={authorName} className="video-thumbnail" />
                <div className="play-button-overlay">
                    <i className="bi bi-play-fill play-icon" />
                </div>
            </a>
            <a href={authorLink} target="_blank" rel="noopener noreferrer" className="author-link">
                {authorName}
            </a>
        </div>
    );
}

export default ProductVideo;
